export const faqData = [
	{
		t: "WHICH WALLET CAN I CONNECT TO MINT A VIP HODLER?",
		p: (
			<>
				You can connect a MetaMask wallet only. If you don’t have one, you can
				create one here:{" "}
				<a target="blank" className="text-white" href="https://metamask.io/">
					https://metamask.io
				</a>
			</>
		),
	},
	{
		t: "HOW DO I CONNECT MY WALLET?",
		p: (
			<>
				DESKTOP: Click on the "Connect" button above. After connecting, make sure you are on the Ethereum network.
				<br></br><br></br>
				METAMASK APP: Please follow the instructions in this video: {" "}
				<a style={{fontWeight: "bold"}} target="blank" className="text-white" href="https://www.youtube.com/watch?v=TYebhWeBhMw">
					HERE
				</a>
				
			</>
		),
	},
	{
		t: "WHAT IS MINTING?",
		p: (
			<>
				Minting a VIP Hodler means that you are buying a digital art which
				becomes part of the Ethereum blockchain. Your digital artwork is
				represented as an NFT so it can be traded in the market and digitally
				tracked as it is sold or collected again in the future.
			</>
		),
	},
	
	{
		t: "ARE THERE ROYALTY FEES?",
		p: (
			<>
				Yes, our royalty fee is 10%. These fees are used for marketing,
				promotion, staff development, and operational costs to ensure we are
				continually growing and expanding the VIP Hodler community.
			</>
		),
	},
];
